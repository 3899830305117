/* CSS VARS */
:root{

  --base-background: #fff;
  --hero-color: #9d4edd;

  /* can be create or edit */
  --fileUploadUseCase: 'create';
  --armAnimationDuration: 1s;
  --interactCursorColor: purple;

  --neon-shadow-color: #db9eff;
  --neon-shadow-color-light: white;

  --myspins-grid-colums: .5fr  .75fr .25fr .5fr 1fr;
  --nav-link-hover-background: rgba(0, 0, 0, 0.1);
}


/* BASE */
#root {
  height: 100%;
  width: 100%;
  display: flex;
  background: var(--base-background);
}

/* NAVIGATION */

.primaryNavBarContainer {
  /* background-color: lightskyblue; */
  position: fixed;
  z-index: 200;
  height: 48px;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* box-shadow: 0 1px 3px rgba(119, 0, 255, 0.219), 0 2px 8px rgba(119, 0, 255, 0.096); */
  border-bottom: 1px solid rgb(187, 187, 187);
  user-select: none;
}

.primaryNavBar {
  /* max-width: 2000px; */
  width: 100%;
  padding: 0px 20px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 1px 3px rgba(119, 0, 255, 0.219), 0 2px 8px rgba(119, 0, 255, 0.096); */
}
.logoTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(137, 69, 192) !important;
  cursor: pointer;
  text-decoration: none !important;
}

.leftNavWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 800px) {
  .collapsedPageList {
    display: flex;

  }
  .fullPageList {
    display: none;
  }

  .logoWrapper {
    height: 24px !important;
    width: 30px !important;
  }
.primaryNavBar {
  padding: 0px 10px 0px 0px;
}

.leftNavWrapper {
  gap: 10px;
}

.crateButtonSeparate {
  display: flex !important;
}
}

@media (max-width: 350px) {
  .crateButtonSeparate > span {
    display: none !important;
  }
}



@media (min-width: 800px) {
  .collapsedPageList {
    display: none;

  }
  .crateButtonSeparate {
    display: none !important;
  }
  .fullPageList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .primaryNavBar {
    padding: 0px 20px;
  }

  .leftNavWrapper {
    gap: 20px;
  }
 
}






.linkContainer {
  display: flex;
  flex-direction: row;
}

.linkItem a {
user-select: none;
  padding: 10px 10px;
  border-radius: 4px;
}

.linkItem * {
  text-decoration: none;
  color: #222;
}

.linkItem:hover a {
  filter: brightness(1.1);
  background-color: var(--nav-link-hover-background);
}

.titleWrapper {
  padding-left: 10px;
}

.navButtonWrapper {
  /* right: 0px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  gap: 4px;
}

.pagesMenuSmallScreen .MuiMenuItem-root {
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

/* .pagesMenuSmallScreen .createButtonInMenu {
  color: var(--hero-color);
} */

.pagesMenuSmallScreen .createButtonInMenu {
  color: var(--hero-color);
}

.createButtonFull {
  color: var(--hero-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 2px;
  padding: 7px 10px !important;
}
.createButtonFull > span, .createButtonFull > svg {
  color: var(--hero-color);
  fill: var(--hero-color);
  text-decoration: none;
}

@media (max-width: 600px) {
  .loginSignupText {
    display: none !important;
  }
  .loginSignupIcon {
    display: inline-flex !important;
  }
}
@media (min-width: 600px) {
  .loginSignupText {
    display: inline-flex !important;

  }
  .loginSignupIcon {
    display: none !important;

  }
}






/* CONTAINER */

.mainContentContainer {
  /* width: 100%;
  height: 98%; */
  flex: 1;
  margin-top: 48px;
  height: calc(100% - 48px);  
  overflow: auto; 
  /* padding: 20px; */
  /* height: 100vh;
     margin: 0 auto;
     overflow: auto; */
}

.embed_mainContainer {
  flex: 1;
  display: flex;
  margin-top: 0;
height: 100%;
width: 100%;
}

.basicContainer {
  width: 100%;
  height: 100%;
}

.randomTextContainer {
  word-wrap: normal;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* INTERACTION */

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  
  align-content: center;
  
}

.react-transform-component {
  justify-content: center;
}

.embedContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: red;
}

.promptWrapper {
  position: absolute;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: none;

opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1200ms;
}




.spinPad {
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 42px;
  /* height: 50px; */
  /* width: 100%; */
  background: rgba(255, 255, 255, .5);
  /* position: absolute; */
  /* bottom: 0;
  left: 0; */
  z-index: 20;
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 10px;
  margin: 2px;
  box-shadow: 0px 0px 12px -4px rgba(100, 100, 100, .5);
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 800ms;
}

.settingsActionButton {
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 800ms;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* FILEPOND */

/* filepond sizes  */
@media (max-width: 600px) {
  .filepond--item {
      width: calc(33% - 0.5em);
  }
}

@media (min-width: 600px) {
  .filepond--item {
      width: calc(20% - 0.5em);
  }
}

@media (min-width: 1200px) {
  .filepond--item {
      width: calc(10% - 0.5em);
  }
}

/* filepond colors  */
.filepond--image-preview-overlay-success,
.filepond--image-preview-overlay-idle {
  color: transparent !important;
}

.filepond--file {
  color: #222 !important;
}

/* .filepond--item[data-filepond-item-state="busy processing"],
.filepond--item[data-filepond-item-state="processing"] {
  display: none;
  transform: none !important;
} */


/* EDIT Page */
.editPageOvararch{
  height: 100%;
}

.basicFocus:focus-visible,
.basicFocus:focus {
  outline: 2px solid black !important;
  outline-offset: 0px !important;
}



.customSliderInputWrapper {
  height: 52px;
  width: 100%;
  /* margin: 16px 0px 16px 0px; */
  position: relative;

}

.customSliderInputBack {
  height: 4px;
  width: 100%;
  /* margin: 16px 0px 16px 0px; */
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(calc(-50%));
  background: rgba(69, 145, 221, .5);
  border-radius: 4px;
  z-index: 9;


}

.customSliderInput {
  width: 100%;
  padding: 14px 0px 18px 0px;
  margin: 0px;
  -webkit-appearance: none;
  height: 20px;
  position: absolute;

  background: transparent;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  z-index: 10;
}

.customSliderInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1976d2;
  cursor: pointer;
  z-index: 10;

}

.customSliderInput::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1976d2;
  cursor: pointer;
  z-index: 10;

}

.customSliderInput:not(.settingsSlider):focus,
.customSettingsFocus:focus
{
  background: transparent !important;
  outline: 2px solid #1976d2;
  outline-offset: -1px;
}

.customNativeSelect:focus
{
  background: grey !important;
  outline: 2px solid #1976d2;
  outline-offset: 2px;
}

.customNativeSelect::before,
.customNativeSelect::after {
  border: none !important;

}

.settingsMenuItem:focus-visible,
.settingsMenuItem:focus {
  background: grey !important;
  outline: 2px solid #1976d2;
  outline-offset: 2px;
}

.customNativeSelect > select {
  padding: 12px 8px;
}
.customNativeSelect:hover {
  background: rgba(0, 0, 0, .04) !important;

}

.settingsSlider:hover {
  cursor: pointer;
  /* background: rgba(0, 0, 0, .04) !important; */

}


.sliderSettingsWrapper:hover {
  /* cursor: pointer; */
  background: rgba(0, 0, 0, .04) !important;

}

.settingsSliderWrapper {
  padding: 12px;
}
/* 
.customNativeSelect::after {
  border: none !important;
  outline: 2px solid #1976d2;
  outline-offset: 4px;
} */


.colorPickerInnerWrapper {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 8px;
}

.colorPickerInnerWrapper > div {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  justify-content: space-between;
}

.hexColorInput {
  /* width: fit-content; */
  width: 10ch !important;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid grey;
  text-align: center;

}

.confirmColorButton {
  background-color: rgb(0, 170, 0) !important;
  color: white !important;

}
.confirmColorButton:hover {
  color: white !important;
  filter: brightness(0.85) !important;
  
}

.wrapperPreviewSettings_Edit {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
}

.previewSection_Edit {
  flex: 2;
  min-width: 375px;
}

.settingsSection_Edit {
  flex: 2;
  min-width: 375px;
  
}

/* .saveLoadingButton.Mui-disabled {
  color: #fff !important;
} */



.editSettingsOverarch {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow: auto; */
  /* background: rgb(250, 250, 250); */
  border-right: 1px solid #ddd;
}

.saveSettingsSectionWrapper {
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: sticky;
  top: 0;
  z-index: 40;
  background: rgba(255, 255, 255, 1);
  /* backdrop-filter: blur(20px); */
}

.savedSettingsDropdownLabel:not(.MuiInputLabel-shrink) {
  transform: translate(14px, 9px) scale(1) !important;
}

.savedSettingsManageIcon {
  
}
.savedSettingsManageIcon:hover {
  background-color: transparent !important;
}
.changeSettingsSectionWrapper {
  overflow: auto;
  height: 100%;

}

.changeSettingsSectionWrapperInner {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px 16px 16px;
}

.changeSettingsSectionGroup {
  border-radius: 10px;
  padding: 10px;
  background: rgba(231, 206, 255, 0.4);
  box-shadow: inset 0 0px 10px -8px rgba(100, 100, 100, 0.8);
  
}


.inputWrapperOverarch {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.inputWrapper {
  /* border-radius: inherit; */
  background:  #ffffff;
  /* box-shadow: 0 0px 10px -8px rgba(50, 50, 50, 1); */
  /* outline: 1px solid rgba(204, 204, 204, .5); */
  border-top: 1px solid #eee;
  /* padding: .5em; */
  /* margin: .25em; */
  /* box-shadow: 0 0px 2px rgba(0, 0, 0, 0.16), 0 0px 8px -4px rgba(0,0,0,0.23); */
}

.inputWrapper:hover {
  /* box-shadow: 0 0px 2px rgba(0, 0, 0, 0.12), 0 0px 3px rgba(0,0,0,0.18); */

}

#firstImageAccordionSummary > .MuiAccordionSummary-content {
  margin: 0px !important;
  font-size: 0.94rem !important;
                        color: #444 !important;
}
/* 2d93e7 */
.basicSwitch > span.MuiSwitch-switchBase.Mui-checked {
color: #2d93e7 !important;
}
.basicSwitch > span.MuiSwitch-track.Mui-checked {
background-color: #2d93e7 !important;
}

.greenSwitch > span.MuiSwitch-switchBase.Mui-checked {
  color: #00c700 !important;
  }
.greenSwitch > span.MuiSwitch-track {
  background-color: #00c700 !important;
}

.greySwitch > span.MuiSwitch-switchBase.Mui-checked {
  color: #6d6d6d !important;
  }
.greySwitch > span.MuiSwitch-track.Mui-checked {
  background-color: #6d6d6d !important;
}

.customSwitchFormControl {
  margin: 0 !important;
  padding: 8px 6px 8px 16px !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between;
}

.customSwitchFormControl > .MuiFormControlLabel-label {
  font-size: 0.94rem !important;
  color: #444 !important;

}

.customSwitchFormControl >.MuiSwitch-root {

}

.MuiFormControl-root > .MuiInputLabel-root {

}

.customSettingsSelect:not(.MuiInputLabel-shrink) {
  transform: translate(14px, 8px) scale(1) !important;
}

/* .MuiSelect-select {
  padding: 10px !important;
  transform: translate(14px, 16px) scale(1);
  -webkit-transform: translate(14px, 16px) scale(1);
} */

.editAccordion {
  border-radius: 10px !important;
  border: 1px solid #ddd !important;
  box-shadow: none !important;
}

.editAccordion::before {
  content: none !important;
}

.editAccordion.Mui-expanded {

  padding-bottom: 10px;
  margin: 0px !important;
  
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 32px !important;
}



.MuiAccordionSummary-content {
  font-size: 1.06rem;
  font-weight: 400;
  color: #240046;
  margin: 12px 0px!important;
}



.MuiAccordionDetails-root {
  padding: 0 !important;
}

.cursorSelectButton {
  width: 100%;
  background: unset;
  border: none;
  font-size: 0.94rem;
  color: #444;
  padding: 12px 16px 12px 16px;
}
.cursorSelectButton:hover {
  cursor: pointer;
}

.cursorOptionsMenuItem:hover,
.cursorOptionsMenuItem:focus {
background: rgba(136, 0, 255, 0.20) !important;
}

@media (max-width: 750px) {
  /* .wrapperPreviewSettings_Edit {
    flex-direction: column;
  } */
  .previewSection_Edit {
    height: 40%;
    order: 1;
  }
  .settingsSection_Edit {
    height: 60%;
    order: 2;

  }

  .saveSettingsSectionWrapper {
    padding: 4px 16px 4px 16px;

  }
}

@media (min-width: 750px) {
  .previewSection_Edit {
    height: 100%;
    order: 2;
  }
  .settingsSection_Edit {
    height: 100%;
    order: 1;
  max-width: 375px;
}
.saveSettingsSectionWrapper {
  padding: 20px 16px 10px 16px;

}
  /* .wrapperPreviewSettings_Edit {
    flex-direction: row;
  } */
}

.disabledOnEdit {
  pointer-events: none;
  cursor: default;
}

/* SETTINGSMENU */
.settingsWrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  /* padding: 0px 0px 4px 0px; */
  /* background-color: lightskyblue; */
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  z-index: 20;
  justify-content: flex-end;
  
}

.settingsWrapperActions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

@media (max-width: 175px) {
  .settingsWrapper {
  flex-direction: column !important;

  }
}

/* this applies to all sliders */
/* .dragFactorSlider > .MuiSlider-markLabel[data-index="0"]{
  position: absolute;
  left: 16px !important;
  text-align: left !important;
  text-decoration: none;

}
.dragFactorSlider > .MuiSlider-markLabel[data-index="1"]{
  position: absolute;
  left: calc(100% - 16px) !important;
}

.opacitySlider > .MuiSlider-markLabel[data-index="0"] {
  position: absolute;
  left: 16px !important;
  text-align: left !important;
  text-decoration: none;
} */



/* BRANDING */
.brandingWrapper {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 4px 8px;
  display: flex;
  z-index: 21;
}

.brandingWrapper * {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: 600;

  /* background-color: purple; */
  /* padding: 2px 4px; */
  /* border-radius: 4px; */
  text-shadow: 1px 1px 4px rgb(255, 255, 255);
  /* box-shadow: 0px 0px 2px rgb(255, 255, 255); */
  opacity: 1;
}

/* MY SPINS */


.mySpinsListOverarch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 5px;
  margin: 0px 5px 20px 5px;
  /* overflow: hidden; */
}

.mySpinsFilter {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 1);
  /* backdrop-filter: blur(20px); */
    width: 100%;
    max-width: 1000px;
    /* padding: 0px 20px 10px 20px; */
    z-index: 30;
    /* box-shadow: 0px 4px 2px -6px rgba(0,0,0, 0.16),0px 10px 4px -8px rgba(0,0,0, 0.08),0px 14px 6px -10px rgba(0,0,0, 0.08); */
    /* 0px 16px 20px -24px #888; */
  border-bottom: 1px solid rgb(182, 182, 182);
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.filterActionsWrapper {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media (max-width: 799px) {
  .filterActionsWrapper {
    display: flex;
    row-gap: 0px;
    column-gap: 10px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: left;
    flex-wrap: wrap;
  }

  .filterWrapperAccordion {
    margin: 6px 0px !important;
  }

  .filterActionsWrapper  .mySpinsFilterSelect {
    margin: 8px 0px 0px 0px !important;
  }

  .filterActionsWrapper .iconButtonLabeled {
    transform: translateY(6px);
  }
}
@media (min-width: 800px) {
  .filterActionsWrapper {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}


.filterWrapperAccordion {
  background: unset !important;
  box-shadow: unset !important;
}

/* .filterWrapperAccordion.Mui-expanded {
  margin: 8px 0px !important;

} */

.filterWrapperAccordion::before {
  content: none;
  display: none;
}

.mySpinsLabelBar {
  display: grid;
  grid-template-columns: var(--myspins-grid-colums);
  padding: 0px 10px;
}

.mySpinsLabelBar > div {
  text-align: center;
  color: #555;
  font-size: .9em;
}

.mySpinsListWrapper {
  padding: 10px;
  width: 100%;
  max-width: 1000px;
  /* overflow: auto; */
  opacity: 1;
	animation-name: mySpinsItemFadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms; 
}

@media (max-width: 599px) {
  .mySpinsListItem {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;  */
    display: grid;
    grid-template-columns: .5fr 1fr;
    grid-template-rows: repeat(4, auto);
    gap: 4px;
    padding: 5px ;
  }

  .mySpinsListItem > div:nth-of-type(1){
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .mySpinsListItem > div:nth-of-type(2){
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .mySpinsListItem > div:nth-of-type(3){
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    
  }
  .mySpinsListItem > div:nth-of-type(4){
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .mySpinsListItem > div:nth-of-type(5){
    grid-row-start: 4;
    grid-row-end: 4;

    grid-column-start: 1;
    grid-column-end: 3;
  }

  .mySpinsActionItems {
    padding: 0;
    justify-content: space-evenly !important;
  }

  .mySpinsCreated::before {
    content: 'Created:';
    padding-right: 5px;
  }

  .mySpinsLabelBar {
    display: none;
  }

  .publicIconLabeled {
    flex-direction: row !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .publicIconLabeled > .MuiTypography-root {
    font-size: 1em;
    transform: none;
  }
.iconButtonLabeled > .MuiTypography-root {
  line-height: unset !important;

}


  /* .publicIconLabeled > svg {
    height: .6em;
    width: .6em;
  } */
}
@media (min-width: 600px) {
  .mySpinsListItem {
    display: grid;
    grid-template-columns: var(--myspins-grid-colums);
    padding: 0px 10px;
    
  }
  
  .mySpinsActionItems {
    padding: 10px 0px;
  }

  
  
}

@media (min-width: 800px) {
  .mySpinsFilter {
    padding-top: 20px;
  }
}

/* @media (max-width: 1000px) {
  .mySpinsFilterSelect {
    max-width: 150px;
  }
} */


.mySpinsActionItemButton {
  color: #2d93e7 !important;
}

.mySpinsActionItemButton:hover {
  background-color: transparent !important;
}

.mySpinsCreated {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySpinsNickname {
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  margin: auto;
}

.mySpinsCreated {
  color: #777;

}

.iconButtonLabeled {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 2px;
}

.iconButtonLabeled:hover {
  background: var(--nav-link-hover-background);

  border-radius: 6px;
  cursor: pointer;
}

.iconButtonLabeled span {
  user-select: none;
  font-size: .8em;
  transform: translateY(-6px);
  color: rgb(39, 121, 189);
}


.publicIconLabeled {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  cursor: default !important;
}

.publicIconLabeled:hover {
  cursor: default !important;
}

.publicIconLabeled span {
  user-select: none;
  font-size: .8em;
  transform: translateY(4px);
  color: rgb(39, 121, 189);
}

.publicIconLabeled.liveIcon span {
  color: rgb(0, 159, 0);

}

.publicIconLabeled.hiddenIcon span {
  color: rgb(157, 157, 157);
  
}
/* 
.mySpinsImage {
  opacity: 1;
	animation-name: mySpinsItemFadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms;
}
*/

@keyframes mySpinsItemFadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
} 

.mySpinsRefreshButton:not([disabled]) > svg {
  color: #2d93e7;
}

.mySpinsListItem:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, .16);

}

.mySpinsListItem > div {
  flex: 1;
}

.mySpinsActionItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-content: flex-start; */
  align-items: center;
  justify-content: center;
  flex: 1;
  }

  @media (min-width: 0px) {
  .mySpinsActionItems {
    gap: 16px;
  }
  }

  @media (min-width: 600px) {
    .mySpinsActionItems {
      gap: 8px;
    }
  }

  @media (min-width: 800px) {
    .mySpinsActionItems {
      gap: 16px;
    }
  }



/* .mySpinsActionItems>* {
margin-right: 8px !important;
margin-bottom: 8px !important;
} */

.mySpinsItemOverarch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.mySpinsItemInfoWrapper {
display: flex;
flex-direction: row;
flex-wrap: wrap;
flex: 1;
align-items: center;
gap: 10px;


}

/* .mySpinsItemInfoWrapper>* {
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  } */

.MuiButton-contained:not(.MuiButton-sizeLarge) {
  line-height: 2em !important;
  height: 2em !important;
}

.mySpinsImageWrapper {
  /* width: 80px; */
  height: 80px;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin: 4px 0px;
}
.mySpinsImage {
  object-fit: contain;
  width: 100%;
  /* max-height: 60px; */
  /* max-height: 100%; */
  max-height: 80px;
  max-width: 100%;
  
}


/* ZOOM */
.fade-enter {
  opacity: 0;
  /* transform: scale(0.7); */
}
.fade-enter-active {
  opacity: 1;
  /* transform: translateX(0); */
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  /* transform: scale(0.7); */
  transition: opacity 500ms, transform 500ms;
}


/* ZOOM IMAGE */

.fade-out-image {
  animation: fadeOut 200ms;
  -webkit-animation: fadeOut 200ms;
  -moz-animation: fadeOut 200ms;
  -o-animation: fadeOut 200ms;
  -ms-animation: fadeOut 200ms;
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0.8;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0.8;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:.8;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0.8;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0.8;}
}

/* LOADING */

.loadingSpinnerWrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TEXT INPUT */

.denseTextFieldWithoutInteraction textarea {
  caret-color: transparent
}


/* FILE UPLOAD SORTING */

.addImagesButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--hero-color);
  cursor: pointer;
  user-select: none;
}

.addImagesButton > svg {
  font-size: 1.5rem !important;
  
}
.addImagesButton  > .addImagesWords {
  color: var(--hero-color);
  font-size: 1rem;
  transform: translateY(2px);
}

.addImagesButton  > .basicActionWords {
  color: #333;
  font-size: 1rem;
  transform: translateY(2px);
}

.addImagesButton  > .continueActionWords {
  color: rgb(0, 131, 0);
  font-size: 1rem;
  transform: translateY(2px);
}

.addImagesButton.addImagesButtonLarge > svg {
  font-size: 3rem !important;
  
}

.addImagesButton.createContinueButton > svg {
  color: rgb(0, 131, 0);

}

.addImagesButton.basicActionButton > svg {
  color: #333;

}

.publicSwitchCreate {

}

.addImagesButton:hover {
  filter: brightness(1.1);
  background-color: var(--nav-link-hover-background);
  border-radius: 6px;
}

.addImagesWords {
  text-align: center;
}

.addImagesButton.addImagesButtonLarge  > .addImagesWords {
  font-size: 1.5rem;
}


.addImagesButton > input {
  display: none;
}

.sortableGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em 1em;
    margin: 20px;
}

@media (min-width: 0px) {
  .sortableGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 250px) {
  .sortableGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 750px) {
  .sortableGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1000px) {
  .sortableGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1250px) {
  .sortableGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1500px) {
  .sortableGrid {
    grid-template-columns: repeat(6, 1fr);
  }
  
}
@media (min-width: 1750px) {
  .sortableGrid {
    grid-template-columns: repeat(7, 1fr);
  }
}
@media (min-width: 2000px) {
  .sortableGrid {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (min-width: 2500px) {
  .sortableGrid {
    grid-template-columns: repeat(9, 1fr);
  }
} 

.sortableImageWrapper {
  position: relative;
  user-select: none;
  /* min-width: 100%;
  min-height: 100%; */
  /* transition: all 2s; */
  /* opacity: 1;
  height: 100%; */
  /* animation-duration: 250ms;
  animation-name: growImageHeight;
  animation-iteration-count: 1;
  animation-timing-function: linear; */
}

/* @keyframes growImageHeight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.removeButtonSortableImage {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  animation-duration: 0s;
  animation-delay: 1s;
  animation-name: delayXButtonLoad;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.indexSortableImage {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  color: #222;
  background: rgba(255, 255, 255, .8);
  /* background: lightblue; */
  padding: 8px;
  font-size: 1rem;
  width: .8em;
  height: .8em;
  border-radius: 10px 0px;
  animation-duration: 0s;
  animation-delay: 1s;
  animation-name: delayXButtonLoad;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  /* text-shadow: 0px 1px 2px rgba(255, 255, 255, .6); */
}
.indexSortableImageInner {
  
}
@keyframes delayXButtonLoad {
  0% {
    opacity: 0;
    height: 0%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

.draggableImage {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none !important;
  /* touch-action: none; */
  object-fit: contain;
  width: 100%;
  height: fit-content;
  aspect-ratio: 1;
  max-height: 16em;
  padding: 4px;
  opacity: 1;
  transition: opacity 600ms;
  /* background-color: rgb(255, 244, 245); */
  /* display: flex; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  max-width: calc( 100% - 8px);
  background: var(--base-background);
  /* cursor: grab; */
  /* max-height: 100%; */
  /* max-height: 16em; */
  /* max-height: 16em; */
  /* max-height: 200px; */
  /* animation-duration: 200ms;
  animation-name: growImageHeight;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-direction: normal; */

}

.imgWrapperDrag {
  user-select: none;
  -webkit-user-select: none;
  /* touch-action: none; */
  -webkit-touch-callout: none !important;
}

.imgWrapperDrag:hover {
  cursor: grab;
}

.currentlyDraggingOverlay {
  cursor: grabbing;

}
.currentlyDraggingOverlay * {
  pointer-events: none;

}


/* FILE UPLOAD */

.imageDropArea {
  width: 200px;
  height: 100px;
  border-radius: 1rem;
  background: rgb(255, 192, 192);
  border: 2px solid coral;
}

.uploadProgressWrapper {
  background-color: #fff;
  padding: 40px 40px;
  margin: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0,0,0,0.23); */
  color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.uploadProgressBar {
  height: 8px !important;
  border-radius: 8px;
  /* background-color: rgba(157, 78, 221, .2) !important; */
}

.uploadProgressBar > span.MuiLinearProgress-bar {
  /* background-color: rgba(157, 78, 221, 1) !important; */

}

.actionWrapperNoFiles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.actionWrapperWithFiles {
  position: relative;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

}

@media (max-width: 600px) {
  .actionWrapperWithFiles {
    gap: 4px;

  }

  .addImagesButton {
    padding: 4px;

  }
}

@media (min-width: 600px) {
  .actionWrapperWithFiles {
    gap: 20px;
  }
  .addImagesButton {
    padding: 20px;

  }
}

/* @media (max-width: 750px) {
  .fileUploadWrapper {
    max-width: 80vw;
  }
  
}
@media (min-width: 750px) {
  .fileUploadWrapper {
    max-width: 40vw;
  }
  
} */


/* BUTTONS  */

.customDisabled {
  pointer-events: none;
  opacity: .5;
}

.buttonWrapperEnableTooltipOnDisable {
  width: fit-content;
  /* display: inline-block; */
  display: flex;
}

.MuiButton-contained:not([disabled]) {
  background: #2d93e7;
}

.MuiButton-contained:not([disabled]):hover {
  background: #2883ce;
}



/* TUTORIALS */
.tutorialOverarch {
  position: relative;
}
.tutorialNav {
  position: fixed;

}

.tutorialContentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tutorialContent {
  max-width: 600px;
}



/* INSPIRE */

.allSpinsWrapper {
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

}

.inspireViewItemWrapper {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0,0,0,0.23);
  /* padding: 8px; */
  border-radius: 10px;
  max-width: 33%;
  position: relative;

}

.inspireItemAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;

}

.inspireSpinItemWrapper {
  overflow: hidden;
  border-radius: 10px;
}



/* LOGO */

@media (pointer: fine) {

.logoWrapper:hover .armOne {
  animation: moveOne var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armTwo {
  animation: moveTwo var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armThree {
  animation: moveThree var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armFour {
  animation: moveFour var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armFive {
  animation: moveFive var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armSix {
  animation: moveSix var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armSeven {
  animation: moveSeven var(--armAnimationDuration) linear 0s infinite normal forwards;
}
.logoWrapper:hover .armEight {
  animation: moveEight var(--armAnimationDuration) linear 0s infinite normal forwards;
}
@keyframes moveOne {
  0% { fill:#bb73f5; transform: translate(0em, 0em ); r: 12; }
  50% { transform: translate(-.135em, -.008em ); r: 11.5; }
  100% { fill: #ab5fe8; transform: translate(-.27em, -.05em); r: 11; }
}
@keyframes moveTwo {
  0% { fill: #ab5fe8; transform: translate(0em, 0em ); r: 11; }
  50% { transform: translate(-.07em, -.07em ); r: 9.5; }
  100% { fill: #9d4edd; transform: translate(-.13em, -.14em); r: 8; }
}
@keyframes moveThree {
  0% { fill: #9d4edd; transform: translate(0em, 0em ); r: 8; }
  50% { transform: translate(.04em, -.09em ); r: 7; }
  100% { fill: #8a39cc; transform: translate(.15em, -.15em); r: 6; }
}
@keyframes moveFour {
  0% { fill: #8a39cc; transform: translate(0em, 0em ); r: 6; }
  50% { transform: translate(.125em, -.03em ); r: 5; }
  100% { fill: #7929ba; transform: translate(.25em, -.05em); r: 4; }
}
@keyframes moveFive {
  0% { fill: #7929ba; transform: translate(0em, 0em ); r: 4; }
  50% { transform: translate(.125em, .02em ); r: 5; }
  100% { fill: #8a39cc; transform: translate(.25em, .05em); r: 6; }
}
@keyframes moveSix {
  0% { fill: #8a39cc; transform: translate(0em, 0em ); r: 6; }
  50% { transform: translate(.11em, .06em ); r: 7; }
  100% { fill: #9d4edd; transform: translate(.15em, .15em); r: 8; }
}
@keyframes moveSeven {
  0% { fill: #9d4edd; transform: translate(0em, 0em ); r: 8; }
  50% { transform: translate(-.05em, .07em ); r: 9.5; }
  100% { fill: #ab5fe8; transform: translate(-.13em, .14em); r: 11; }
}
@keyframes moveEight {
  0% { fill: #ab5fe8; transform: translate(0em, 0em ); r: 11; }
  50% { transform: translate(-.145em, 0.042em ); r: 11.5; }
  100% { fill: #bb73f5; transform: translate(-.27em, .05em); r: 12; }
}

}




/* ================
PRICING PAGE 
================ */



.priceWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
  justify-content: center;
}



.priceGroup {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* background: rgb(255,255,255); */
  /* outline: 2px solid rgba(157, 78, 221, .6); */
  /* background: rgba(201,145,255,.2); */

/* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(157, 78, 221, .25) 100%); */
background: white;
border-radius: 10px;
box-shadow:  0px 0px 2px rgba(0,0,0, 0.2),
0px 4px 4px rgba(0,0,0, 0.1),
0px 6px 6px rgba(0,0,0, 0.1);
/* box-shadow: inset rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, inset rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.priceTitleSection {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  color: rgb(65, 65, 65);
}

.priceAmountSection {
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  color: var(--hero-color);
}
.priceDescriptionSection {

}
.priceActionSection {

}


/* ================
ERROR PAGE 
================ */

.errorPageOverarch {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.errorWrapper {
  position: relative;
  max-width: 800px;
  margin: 20px;
  /* padding: 10px 32px 20px 32px; */
  padding: 20px 32px;
  border-radius: 10px;
  /* background: rgba(157, 78, 221, .1); */
  /* border: 2px solid rgba(157, 78, 221, .8); */
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.16);
  display: flex;
flex-direction: column;
}

.errorInnerWrapper {
  display: flex;
flex-direction: column;
}

.errorTitle {
  /* background: rgba(157, 78, 221, .5); */
  margin-bottom: 20px;
  text-align: center;
  display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
font-weight: 700;
font-size: 32px;
}


/* ================
HOME PAGE 
================ */

@media (max-width: 750px) {
  .welcomeItemWrapper {
    flex: 1;
    width: 100%;
  }
  .welcomeWrapper {
    width: 100%;
    flex-direction: column;
  }
 
}

@media (min-width: 750px) {
  .welcomeItemWrapper {
    flex: 1;
    min-width: 375px;
    min-height: 300px;
  }
  .welcomeSpin {
    height: 300px !important;
  }
  .welcomeWrapper {
    /* width: calc(100% - 40px); */
    width: 100%;
    flex-direction: row;
  }
}
/* 
.actionItemWrapper *:not(.MuiSvgIcon-root) {
  font-size: 1.4rem;
} */

/* ================
ACCOUNT PAGE 
================ */

.userNameChangeTextField .MuiOutlinedInput-input {
  padding: 8.5px 34px 8.5px 14px;
}

.accountSectionOverarch {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 20px;
}

.accountSection {
  border: 1px solid rgb(192, 192, 192);
  padding: 20px;
  border-radius: 10px;
}

.accountSectionHeader {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.accountSectionContent {
  font-size: 1rem;
}

.usageGlance {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}